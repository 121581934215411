<template>
    <div>
        <Header></Header>
        <div class="nbanner">
          <img src="@/assets/images/newbanner.jpg" alt="">
        </div>
        <div class="main">
            <div class="info">
                <div class="title">{{ $t('ContactUs1') }}</div>
                <div class="list">
                    <div class="left">
                       <img src="@/assets/images/about.jpg" alt="">
                    </div>
                    <div class="right">
                        <p class="p1">{{ $t('aboutUs') }}</p>
                        <!-- <div class="list1">
                            <div class="rleft1">{{ $t('address') }}:</div>
                            <div class="rright1">{{list.company_address}} <br> {{list.company_address_en}}</div>
                        </div>
                        <div class="list1">
                            <div class="rleft1">{{ $t('telephone') }}:</div>
                            <div class="rright1">{{list.site_tel}}</div>
                        </div>
                        <div class="list1">
                            <div class="rleft1">{{ $t('fax') }}:</div>
                            <div class="rright1">{{list.site_fax}}</div>
                        </div>
                        <div class="list1">
                            <div class="rleft1">{{ $t('em') }}:</div>
                            <div class="rright1">{{list.site_email}}</div>
                        </div>
                        <div class="img">
                            <div class="leftimg">
                                <img :src="list.dongheng_official_account" alt="">
                            </div>
                            <div class="rightimg">
                                <img :src="list.xinzhanhui_official_account" alt="">
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "./components/Header";
import Footer from "./components/Footer";

export default {
  name: "AboutUs",
  inject: ['reload'], // 注入依赖
  components: {
    Footer,
    Header,
  },
  data() {
    return {
        list: []
    }
  },
  mounted () {
      this.getList()
  },
  methods: {
    // 获取联系我们
    getList () {
      this.$common.fetchList('/index/about/us', {}).then(data => {
        if (data.code === 200) {
            const result = data.data
            this.list = result
            console.log(result)
        }
      }).catch(() => {
        this.$notification.error({
        message: '错误',
        description: '请求信息失败，请重试'
        })
      })
    },
  }
}
</script>

<style lang="less" scoped>
  @import '~@/assets/css/aboutus.less';
</style>
